import React from "react"
import Button from "@material-ui/core/Button"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage } from "gatsby-plugin-image"
// import CLICarousel from "../components/cli-video/CLICarousel"
// import { CLILPFeatures } from "../components/landingPage/CLILPFeatures"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px",
    },
  },
  button: {
    marginTop: "40px",
    marginBottom: "20px",
  },
}))

const CliLandingPage = ({ data, location }) => {
  const classes = useStyles()

  return (
    <Layout>
      <HeroSection>
        <Hero
          title={
            <span>
              Use the Simpleen Machine <br />
              Translation Developer Tool
            </span>
          }
          subtitle=" "
          callToAction="Set up your CLI"
          callLink="/cli-documentation"
          source="Setup Hero CLI"
        />
      </HeroSection>
      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="center" alignItems="top">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              CLI Tool to Automate i18n Translation
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              With Simpleen you can automatically machine translate your i18n
              locales and easily internationalize software, games and (Web)
              Apps. Save time while reaching more users with your
              product/service.
              <br />
              <br />
              Simpleen CLI is built for developers. It integrates into your
              developer tools and workflow. You then automatically translate
              i18n locales to your desired target languages. We currently
              support the translation of 12 different languages via DeepL.
              <br />
              <br />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              We currently support JSON based i18n libraries like
            </Typography>
            <div style={{ margin: "0 auto" }}>
              <GatsbyImage
                image={data.supported.childImageSharp.gatsbyImageData}
                alt="Supported i18n libraries like Format.JS, ngx-translate and i18next"
                style={{ display: "block", margin: "0 auto" }}
              />
            </div>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Button
              size="large"
              component={Link}
              to="/signup"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (window && window.plausible) {
                  window.plausible("Signup ButtonCLI")
                }
              }}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Container>
      {/*<CLILPFeatures source="Signup ButtonCLI" />*/}

      <Seo
        general={{
          title: "Translate i18n locales",
          description: "Machine Translation of your i18n software project",
          path: location.pathname,
          image: data.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const i18nCliLPQuery = graphql`
  query i18nCliLPuery {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    supported: file(relativePath: { eq: "libraries/CLIJSONLibraries.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          quality: 95
          layout: FIXED
          placeholder: BLURRED
        )
      }
    }
    video: file(relativePath: { eq: "logos/i18nAngular.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 95
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
  }
`

export default CliLandingPage

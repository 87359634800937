/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type ContentType =
  | "website"
  | "article"
  | "profile"
  | "book"
  | "APIReference"
  | "video.movie"
  | "video.episode"
  | "video.tv_show"

type schema =
  | "Organization"
  | "Person"
  | "Product"
  | "Offer"
  | "Review"
  | "Event"
  | "CreativeWork"
  | "Blog"

/*
interface SEOProps {
  schema: schema;
  title: string;
  description: string;
  path: string;
  contentType: contentType;
  published?: string;
  updated?: string;
  category?: string;
  tags?: string[];
  twitter: string;
}
*/

type SEOProps = {
  blogPosting?: BlogPosting
  general: GeneralSEOProps
  corporation?: Corporation
}

type BlogPosting = {
  // A brief description
  description: string
  // The content of the whole blog post
  // articleBoarticleBody: string;
  // section or category
  section: string
  // Tags of this article
  tags: string[]

  // title
  headline: string
  // Becomes @type: ImageObject
  image: {
    url: string
    height: number
    width: number
    title: string
  }
  datePublished: string
  dateModified: string
  //PublishingBlog
  publishingBlog: string
  // Name of person
  author: {
    name: string
    twitter: string
  }
}

type Corporation = {
  schema: "Corporation"
  name: string
  url: string
  logo: ""
  sameAs: []
}

type GeneralSEOProps = {
  path: string
  language?: "de" | "en" | "de, en"
  title: string
  description: string
  contentType: ContentType
  image: string
}
/*
const seoImages = {
  openGraph: [
    "open-graph-blue.png",
    "open-graph-green.png",
    "open-graph-red.png",
    "open-graph-yellow.png"
  ],
  twitter: [
    "twitter-card-blue.png",
    "twitter-card-green.png",
    "twitter-card-red.png",
    "twitter-card-yellow.png"
  ],
  google: [
    "google-blue.png",
    "google-green.png",
    "google-red.png",
    "google-yellow.png"
  ]
};

const seoImageURL = (file: string) =>
  `https://s3.amazonaws.com/tmc-site-assets/graphics/${file}`;
*/
const seoURL = (path: string, blogPosting?: BlogPosting) => {
  if (blogPosting && blogPosting.publishingBlog === "TwteamAndSimpleen") {
    return path[path.length - 1] === "/"
      ? `https://twteam.ch${path.substr(0, path.length - 1)}`
      : `https://twteam.ch${path}`
  }
  return path[path.length - 1] === "/"
    ? `https://simpleen.io${path.substr(0, path.length - 1)}`
    : `https://simpleen.io${path}`
}

/* This function creates the meta tags */
const getMetaTags = (
  data: GeneralSEOProps,
  blogPosting: BlogPosting
  /*
  {
  title,
  description,
  path,
  contentType,
  published,
  updated,
  category,
  tags,
  twitter
}: SEOProps*/
) => {
  const metaTags: { name?: string; property?: string; content: string }[] = [
    /*
    { itemprop: "name", content: data.title },
    { itemprop: "description", content: data.description },
    { itemprop: "image", content: seoImageURL(_.sample(seoImages.google)) },
    */
    { name: "description", content: data.description },
    { property: "og:title", content: `${data.title}` },
    { property: "og:type", content: data.contentType },
    { property: "og:url", content: seoURL(data.path) },
    { property: "og:description", content: data.description },
    { property: "og:site_name", content: "simpleen" },
    { property: "og:locale", content: "en_US" },
  ]

  if (blogPosting) {
    const blogImage =
      blogPosting.image.url && blogPosting.image.url.substr(0, 2) === "//"
        ? `https:${blogPosting.image.url}`
        : blogPosting.image.url

    // Use Twitter cards especially for blogPostings
    metaTags.push(
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@simpleen_io" },
      { name: "twitter:title", content: `${data.title}` },
      { name: "twitter:description", content: blogPosting.description },
      { name: "twitter:url", content: seoURL(data.path) },
      {
        name: "twitter:creator",
        content: blogPosting.author.twitter || "@denisaugsburger",
      },
      {
        name: "twitter:image",
        content: blogImage,
      },
      {
        name: "twitter:image:src",
        content: blogImage,
      },
      {
        name: "twitter:image:alt",
        content: blogPosting.image.title,
      },

      // Linked In
      { name: "image", property: "og:image", content: blogImage },
      { name: "author", content: blogPosting.author.name }
    )

    metaTags.push(
      { name: "article:published_time", content: blogPosting.datePublished },
      { name: "article:modified_time", content: blogPosting.dateModified },
      { name: "article:author", content: blogPosting.author.name }
      // { name: "article:section", content: blogPosting.section }
      // { name: "article:tags", content: blogPosting.tags.toString() }
    )
  } else {
    const image =
      data.image && data.image.substr(0, 1) === "/"
        ? `https://simpleen.io${data.image}`
        : data.image

    metaTags.push(
      // Twitter share website
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@simpleen_io" },
      { name: "twitter:title", content: data.title },
      { name: "twitter:description", content: data.description },
      { name: "twitter:image", content: image },
      { name: "twitter:image:src", content: image },
      { name: "twitter:image:alt", content: data.title },

      // Linked In
      { name: "image", property: "og:image", content: image }
    )
  }

  return metaTags
}

const getBlogPosting = (data: BlogPosting, logo) => {
  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: data.headline,
    image: {
      "@type": "ImageObject",
      url: data.image.url,
      width: data.image.width,
      height: data.image.height,
    },
    author: {
      "@type": "Person",
      name: data.author.name,
    },
    publisher: {
      "@type": "Organization",
      name: "twteam",
      logo: {
        "@type": "ImageObject",
        url: logo.childImageSharp.gatsbyImageData.src,
        width: logo.childImageSharp.gatsbyImageData.width,
        height: logo.childImageSharp.gatsbyImageData.height,
      },
    },
    datePublished: data.datePublished,
    dateModified: data.dateModified,
  };
}

const SEO: React.FC<SEOProps> = ({ blogPosting, corporation, general }) => {
  const data = useStaticQuery(
    graphql`query LogoSEO {
  logo: file(relativePath: {eq: "simpleen-logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 381, quality: 100, layout: FIXED)
    }
  }
}
`
  )

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: general.language ? general.language : "en-US",
        }}
      />

      <Helmet
        link={[{ rel: "canonical", href: seoURL(general.path, blogPosting) }]}
      />
      <Helmet title={general.title} defer={false} />
      <Helmet meta={getMetaTags(general, blogPosting)} />

      {blogPosting && (
        <Helmet>
          <script type="application/ld+json">
            {`
            ${JSON.stringify(getBlogPosting(blogPosting, data.logo))}
          `}
          </script>
        </Helmet>
      )}

      {/* Evt. erweitern um: {
      "sameAs": [
        "https://twitter.com/@asdfasdf"
      ]
      } */}
      {corporation && (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Corporation",
              "name": "simpleen",
              "url": "https://simpleen.io",
              "logo": "${data.logo.fixed.src}"
            }
          `}
          </script>
        </Helmet>
      )}
    </>
  )
}

export default SEO

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FunctionComponent } from 'react';

const useStyles = makeStyles(theme => ({
  blueBackground: {
    background: `linear-gradient(to top, #0f4c81, ${theme.palette.primary.dark})`
  }
})
)

type Props = {}

export const HeroSection: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    return (
      <div className={classes.blueBackground}>
        <Container maxWidth="lg">{props.children}</Container>
      </div>
    )

}
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import cx from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import Footer from "./footer"

import Header from "./header"
// import "./layout.css"

const useStyles = makeStyles(theme => ({
  dark: {
    backgroundColor: "#ccc"
  },
  box: {
    display: "flex",
    flexFlow: "column",
    minHeight: "100vh"
  },
  header: {
    flex: "0 1 auto",
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
  },
  main: {
    flex: "1 1 auto"
  },
  footer: {
    flex: "0 1 auto",
  },
}))

const Layout = ({ children, dark }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const mainClass = dark ? cx(classes.main, classes.dark) : classes.main

  return (
    <>
      <CssBaseline />
      <div className={classes.box}>
        <Header siteTitle={data.site.siteMetadata.title} className={classes.header} />
        <main className={mainClass}>{children}</main>
        <Footer className={classes.footer} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool
}

export default Layout

import React from "react"
import { makeStyles, Typography, Grid, Button } from "@material-ui/core"
import { Link } from "gatsby"
// import { graphql, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: "25px",
    textAlign: "center",
  },
  heroText: {
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  heroImage: {
    maxWidth: "700px",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      margin: "20px 0 20px 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "50px 0 10px 0",
    },
  },
  paragraph: {
    color: "white",
    fontSize: "1.25rem",
    margin: "20px 0",
  },
  callToAction: {
    wordSpacing: "0.3rem",
  },
  text: {
    fontSize: "1.25rem",
    marginTop: "0.5rem",
  },
}))

type Props = {
  subtitle?: string
  title?: string
  callToAction?: string
  callLink?: string
  source?: string
}

const Hero = (props: Props) => {
  const classes = useStyles()
  const { subtitle, title, callToAction, callLink, source } = props

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={12} className={classes.heroText}>
        <Typography variant="h1" color="secondary" className={classes.title}>
          {title ? title : "Do More With Machine Translation"}
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          {subtitle
            ? subtitle
            : "Translate i18n Projects, Documentations or Use It in Your No Code Workflows"}
        </Typography>

        <Button
          component={Link}
          to={callLink ? callLink : "/signup"}
          color="secondary"
          variant="outlined"
          className={classes.callToAction}
          onClick={() => {
            window &&
              // @ts-ignore
              window.plausible &&
              // @ts-ignore
              window.plausible(source ? source : "hero-signup")
          }}
        >
          {callToAction ? callToAction : "Start translating"}
        </Button>
      </Grid>
    </Grid>
  )
}

Hero.propTypes = {}

Hero.defaultProps = {}

export default Hero
